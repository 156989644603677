import React from 'react';
import Helmet from 'react-helmet';

const Facebook = props => (
  <Helmet>
    {/* Open Graph / Facebook */}
    <meta property="og:locale" content="en_US"/>
    <meta property="og:type" content="article"/>
    <meta property="og:url" content={props.url}/>
    <meta property="og:title" content={props.seo?.title}/>
    <meta property="og:description" content={props.seo?.description}/>
    <meta property="og:site_name" content="Age Times"/>
    {props.seo?.image && <meta property="og:image" content={props.seo.image.url}/>}
    {props.seo?.image && <meta property="og:image:width" content={props.seo.image.width}/>}
    {props.seo?.image && <meta property="og:image:height" content={props.seo.image.height}/>}
  </Helmet>
);

export default Facebook;
