/*import {isMobile} from "react-device-detect";
import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil";*/

const AdHeader = () => {

  /*useEffect(() => {
      if(isMobile === null) {
          return;
      }
      window.googletag = window.googletag || {cmd: []};
      addTimeout(() => {
          if (window.googletag) {
              googletag.cmd.push(function () {
                  try {
                      googletag.display('div-gpt-ad-1662966807713-0');
                  } catch (e) {

                  }
              });
          }
      }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
  }, [isMobile]);*/

  return '';/*<div className="ad-container mb-2">
        <div className="advertisement-text">Advertisement</div>
        <div id='div-gpt-ad-1662966807713-0'></div>
    </div>*/
};

export default AdHeader;
