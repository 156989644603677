const FOLLOW_US = {
  iconFillColor: '#007996',
  isFacebook: true,
  isTwitter: true,
  isInstagram: true,
  isYoutube: true,
  isGoogleNews: false
};

Object.freeze(FOLLOW_US);

export {FOLLOW_US};
