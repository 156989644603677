const timeout = [];
const addTimeout = (callback, delay, ...args) => {
  const timeId = setTimeout(callback, delay, ...args);
  timeout.push(timeId);
  return timeId;
};
const cancelAlltimeout = () => {
  timeout.forEach((timeId) => {
    clearTimeout(timeId);
  });
  timeout.length = 0;
};
export {addTimeout, cancelAlltimeout};
