import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {URL} from '../../constants/urls';
import {STRINGS} from '../../constants/strings';
import {Footer} from '@cg-squad/ui-components';
import mixpanel from '../../constants/mixpanel';

const FooterWrapper = (props) => {
  const data = useStaticQuery(graphql`
    query FooterCategoryQuery {
      allCategories: allDatoCmsCategory(
        filter: {root: {eq: true}, slug: {ne: "news"}, website: {elemMatch: {name: {eq: "at"}}}}
        sort: {position: ASC}
      ) {
        nodes {
          slug
          title
          domains {
            name
          }
        }
      }
    }`
  );

  data.allCategories.nodes = data.allCategories.nodes.filter(node => {
    return node.domains.find(domain => domain.name === process.env.GATSBY_DOMAIN);
  });

  return <Footer website="at"
                 allCategories={data.allCategories}
                 stringsConst={STRINGS} urlsConst={URL}
                 mixpanel={mixpanel}/>;
};

export default FooterWrapper;
