import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {STRINGS} from '../../constants/strings';
import AdHeader from '../ads/adHeader';
import {URL} from '../../constants/urls';
import {FOLLOW_US} from '../../constants/followUs';
import {Header} from '@cg-squad/ui-components';
import mixpanel from '../../constants/mixpanel';

const HeaderWrapper = props => {

  const data = useStaticQuery(graphql`
    query HeaderCategoryQuery {
      allCategories: allDatoCmsCategory(
        filter: {root: {eq: true}, website: {elemMatch: {name: {eq: "at"}}}}
        sort: {position: ASC}
      ) {
        nodes {
          slug
          title
          domains {
            name
          }
          treeChildren {
            slug
            title
            position
            domains {
              name
            }
          }
        }
      }
    }`
  );

  const categories = {
    nodes: []
  };

  for (let node of data.allCategories.nodes) {
    const children = [];
    for (let subcategory of node.treeChildren) {
      if (!subcategory.domains
        || !subcategory.domains.length
        || !subcategory.domains.find(domain => domain.name === process.env.GATSBY_DOMAIN)) {
        continue;
      }
      children.push(subcategory);
    }
    node.children = node.treeChildren = children;

    if (node.domains.find(domain => domain.name === process.env.GATSBY_DOMAIN)) {
      categories.nodes.push(node);
    }
  }

  const logoSizes = {
    menuLogoWidth: 270,
    menuLogoHeight: 40,
    headerLogoWidth: 400,
    headerLogoHeight: 61
  };

  return <Header path={props.path}
                 categoryPath={props.categoryPath}
                 subCategoryPath={props.subCategoryPath}
                 onSubscriptionPopupStateChange={props.onSubscriptionPopupStateChange}
                 allCategories={categories}
                 stringsConst={STRINGS} urlsConst={URL}
                 AdHeader={AdHeader}
                 followUs={FOLLOW_US}
                 logoSizes={logoSizes}
                 hideSearch={true}
                 hideCategories={props.hideCategories}
                 mixpanel={mixpanel}/>;
};


export default HeaderWrapper;
